.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-float infinite 3s ease-in-out;
  }
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

.App-link {
  color: rgb(112, 76, 182);
}

.ta-menu-flags > button {
  height: 50px;
  border: none;
  outline: none;
}

.ta-menu-flags .ReactFlagsSelect-module_selectFlag__2q5gC {
  font-size: 1.5rem !important;
}

.otp-input > input {
  width: calc(100% / 6) !important;
  border: 1px solid #D9D9D9;
  height: 50px;
  outline: none;
  border-radius: 3px;
  user-select: none;
}

.otp-input > input::-webkit-outer-spin-button,
.otp-input > input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
  -moz-appearance: textfield;
}

.wysiwyg-editor {
  padding: 10px 20px;
  border: 1px solid #F1F1F1;
}

.date-picker > div:first-of-type, .date-picker > div:first-of-type > div {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.react-datepicker__input-container {
  height: 100%;
}

.react-datepicker__input-container > input {
  height: 100%;
  width: calc(100% + 20px);
  font-size: 0.875rem;
  outline: none;
}

select {
  background: url("data:image/svg+xml;utf8,<svg viewBox='0 0 140 140' width='24' height='24' xmlns='http://www.w3.org/2000/svg'><g><path d='m121.3,34.6c-1.6-1.6-4.2-1.6-5.8,0l-51,51.1-51.1-51.1c-1.6-1.6-4.2-1.6-5.8,0-1.6,1.6-1.6,4.2 0,5.8l53.9,53.9c0.8,0.8 1.8,1.2 2.9,1.2 1,0 2.1-0.4 2.9-1.2l53.9-53.9c1.7-1.6 1.7-4.2 0.1-5.8z' fill='%23CCC'/></g></svg>") no-repeat;
  background-position: center right 10px;
  background-size: 16px auto;
  background-color: #fff;
}

@keyframes App-logo-float {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(10px);
  }
  100% {
    transform: translateY(0px);
  }
}
